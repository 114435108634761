import moment from "moment";
// 酒店报表部门列表
import req_departmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList";
// 统计部门酒店订单分析列表
import statement_queryHotelEconomizeAndLoss from "@/lib/data-service/haolv-default/consumer_t_od_hotel_statement_queryHotelEconomizeAndLoss";
export default {
  data() {
    return {
      loading: false,
      form: {
        deptId: [""],
        startDate: "",
        endDate: "",
      },
      detail: {},
      economize: {},
      deficiency: {},

      overproof: {},

      hotelOptions: {},

      list: [],
      dateRange: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      departmentList: [],
    };
  },
  activated() {
    this.init();
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    req_datas() {
      if (this.loading) return;
      this.loading = true;
      const { deptId, startDate, endDate } = this.form;
      const params = { deptId: deptId[deptId.length - 1], startDate, endDate };
      statement_queryHotelEconomizeAndLoss(params)
        .then((res) => {
          let {
            hotelEconomizeResultList,
            hotelLossResultList,
            hotelOverproofResultList,
          } = res;
          this.list = hotelOverproofResultList;

          this.economize = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                data: hotelEconomizeResultList.map((item) => `${item.month}月`),
                axisPointer: {
                  type: "shadow",
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: "金额",
                axisLabel: {
                  formatter: "{value} 元",
                },
              },
              {
                type: "value",
                name: "节省率",
                axisLabel: {
                  formatter: "{value} %",
                },
              },
            ],
            series: [
              {
                name: "金额",
                type: "bar",
                barMaxWidth: "15",
                data: hotelEconomizeResultList.map(
                  (item) => item.economizeAmount
                ),
              },
              {
                name: "节省率",
                type: "bar",
                yAxisIndex: 1,
                barMaxWidth: "15",
                data: hotelEconomizeResultList.map(
                  (item) => item.economizeRate.toFixed(2)
                ),
              },
            ],
          };

          this.deficiency = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            xAxis: [
              {
                type: "category",
                data: hotelLossResultList.map((item) => `${item.month}月`),
                axisPointer: {
                  type: "shadow",
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: "金额",
                axisLabel: {
                  formatter: "{value} 元",
                },
              },
              {
                type: "value",
                name: "损失率",
                axisLabel: {
                  formatter: "{value} %",
                },
              },
            ],
            series: [
              {
                name: "金额",
                type: "bar",
                barMaxWidth: "15",
                data: hotelLossResultList.map((item) => item.lossAmount),
              },
              {
                name: "损失率",
                type: "bar",
                yAxisIndex: 1,
                barMaxWidth: "15",
                data: hotelLossResultList.map((item) => item.lossRate.toFixed(2)),
              },
            ],
          };

          this.overproof = {
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            legend: {
              orient: "vertical",
              left: "left",
              data: hotelOverproofResultList.map(
                (item) => item.overproofReason
              ),
            },
            series: [
              {
                name: "超标原因",
                type: "pie",
                radius: "55%",
                center: ["50%", "50%"],
                data: hotelOverproofResultList.map((item) => {
                  return { name: item.overproofReason, value: item.roomNight };
                }),
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init() {
      this.req_datas();
      req_departmentList()
        .then((res) => {
          let departmentList = res.datas.departmentListResults;
          departmentList.unshift({ deptName: "全部", deptId: "" });
          this.departmentList = departmentList;
        })
        .catch((err) => {});
    },
    change_date(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0];
        form.endDate = val[1];
      } else {
        form.startDate = "";
        form.endDate = "";
      }
    },
  },
};
